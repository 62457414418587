import Button from '@mui/material/Button';
import { RadioGroup, Radio, FormControlLabel, Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Modale from '../modale/modale';
import { useAppContext } from '../context/AppContext';
import { useDirectiveContext } from '../context/DirectiveContext';

import '../css/global.css';
import '../css/vraiFaux.css';

// DÉFINITION DES INTERFACES ----------------------------
interface MonChoixProgramme {
    titre: string;
    directives: {
        titre: string;
        objectifs: string[];
        but: string;
    }[];
    piedPage: string;
    question: {
        facteur: string;
        questions: {
            id: string;
            type: string;
            question: string;
        }[];
    }[];
}

interface Question {
    id: string;
    type: string;
    question: string;
}

interface Reponse {
    id: string;
    type: string;
    reponse: string;
};

interface QuestionReponse {
    type: string;
    reponsesNegatives: Reponse[];
    reponsesPositives: Reponse[];
}

// -------------------------------------------------------

export default function VraiFaux() {
    // DÉCLARATION DES VARIABLES
    const [listeFacteur, setListeFacteur] = useState<string[]>([]);
    let totalQuestionnaire : number = listeFacteur.length;
    let totalQuestionnaireRepondu : number = 0;
    let totalQuestionnairePage : number = 5;
    const [facteurIndex, setFacteurIndex] = useState(0); 
    const [facteurActuel, setFacteurActuel] = useState<string>("I");
    let questionnaire : number = facteurIndex + 1;
    const [questionsParFacteur, setQuestionsParFacteur] = useState<Question[]>([]);
    const [questionsParFacteurRepondu, setQuestionsParFacteurRepondu] = useState<QuestionReponse[]>([]);
    const [titre, setTitre] = useState<string>("");
    const [sousTitre, setSousTitre] = useState<string>("");
    const {setTitreDirective, setObjectifs} = useDirectiveContext();
    // -----------------------------------------------------------------------------------------------

    // DÉCLARATION DES FONCTIONS
    const handlePrevClick = () => {
        if (facteurIndex > 0) {
            const newFacteurIndex = facteurIndex - 1;
            setFacteurIndex(newFacteurIndex);
            setFacteurActuel(listeFacteur[newFacteurIndex]);
            getByType(listeFacteur[newFacteurIndex]);
            getReponseByType(listeFacteur[newFacteurIndex]);
        }
    };
    
    const handleNextClick = () => {
        if (facteurIndex < listeFacteur.length - 1) {
            const newFacteurIndex = facteurIndex + 1;
            setFacteurIndex(newFacteurIndex);
            setFacteurActuel(listeFacteur[newFacteurIndex]);
            getByType(listeFacteur[newFacteurIndex]);
            getReponseByType(listeFacteur[newFacteurIndex]);
        }
        else {
            verifyAllAnswer();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {settingApiAddress, bearerToken} = useAppContext();
      async function getAllQuestions() { // sous titre et titre
        const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/getAllQuestions", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + bearerToken,
            },
          });
          try {
            const data = await questionsData.json() as MonChoixProgramme[];
            setTitre(data[0].titre);
            setSousTitre(data[0].piedPage);
            setTitreDirective(data[0].directives[0].titre);
            setObjectifs(data[0].directives[0].objectifs);
            // recuperer tout les facteurs
            const types = data[0].question.map(q => q.questions.map(q => q.type)).flat(); // aplatir le tableau (pour eviter d'avoir plein de tableau de type)
            setListeFacteur(types.filter((v, i, a) => a.indexOf(v) === i)); // filtre les doublons
 
          } catch (error) {
            console.error(error);
          }
    }

    async function getByType(type : string) { 
        // recuperer les questions par type (facteur)
        const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/getByType/" + type, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + bearerToken,
            },
          });
          try {
            const data = await questionsData.json() as Question[];
            setQuestionsParFacteur(data);
          } catch (error) {
            console.error(error);
          }
    }

    async function getReponseByType(type : string) {
        try {
            // recuperer les réponses au question par type (facteur)
            const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/getReponseByType/" + type, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + bearerToken,
                },
            });
            if (questionsData.status === 404) {
                // console.log("Aucune réponse de répondu dans la section");
                return;
            } else {
                const data = await questionsData.json();
        
                if (data && data.reponsesPositives && data.reponsesNegatives) {
                    const questionReponse = {
                        type: data.type,
                        reponsesPositives: data.reponsesPositives,
                        reponsesNegatives: data.reponsesNegatives
                    };
                    setQuestionsParFacteurRepondu([questionReponse]);
                } else {
                    // aucune réponse de répondu dans la section
                }
            }
        } catch (error) {
            // console.error(error);
        }
    }

    async function addQuestionToUser(id : string, type : string, choixReponse : string) {
        // ajouter la réponse de l'utilisateur
        const reponse: Reponse = {	id: id, type: type, reponse: choixReponse};
        const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/addQuestionToUser/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
            body: JSON.stringify({
                question: reponse
            })
        });
        try {
            if (questionsData.ok) {
                // console.log("Réponse ajoutée avec succès");
                getReponseByType(facteurActuel);
            } else {
                // Afficher une erreur
            }
            

        } catch (error) {
            console.error(error);
        }
    }

    async function verifyAllAnswer() {
        // vérifier si toutes les questions ont été répondues
        const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/verifyAllAnswer", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
        });
        try {
            const data = await questionsData.json();
            if (data.allAnswered === true ) {
                handleNextForms();
            }
            else {
                setPhrase("Vous n'avez pas répondu à toutes les questions, veuillez répondre à toutes les questions avant de terminer.");
                setRoute("/vrai-faux");
                setOpen(true);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    // Fonction pour gérer le changement de réponse
    const handleRadioChange = (id : string, type : string, reponse : string) => {
        addQuestionToUser(id, type, reponse);
    };


    useEffect(() => {
        // Récupérer les questions et réponses du premier facteur
        if (bearerToken !== "") {
            getAllQuestions();
            getByType(facteurActuel);
            getReponseByType(facteurActuel);
      }
    }, [])

    const [open, setOpen] = useState(false);
    const [phrase, setPhrase] = useState('');
    const [route, setRoute] = useState('');
    const handleNextForms = () => {
        // Définir la phrase et la route pour la modale
        setPhrase("Vous avez terminé le questionnaire, cliquez sur terminer pour voir les résultats de cette section.");
        setRoute("/resultat");
        setOpen(true);
      };

  return (
        <Box className="main">
            <Box className="page-box">
            <Typography className="titre">{titre}</Typography>
                <form className="form-style">
                    <Box className="box-titre-formulaire">
                        <Typography className="titre-texte">{questionnaire}/{totalQuestionnaire}</Typography>
                        <Typography className="titre-texte">Facteur {facteurActuel}</Typography>
                        <Typography className="titre-texte"></Typography>
                    </Box>
                    <Box className="box-formulaire">
                        <Box className="box-texte-formulaire">
                            <Typography className="question-texte">Question</Typography>
                            <Box className="vrai-faux">
                                <Typography className="option-texte">Vrai</Typography>
                                <Typography className="option-texte">Faux</Typography>
                            </Box>
                        </Box>
                        <Box className="question-list-box">
                        {questionsParFacteur.map((question, index) => {
                            // Find the corresponding question in questionsParFacteurRepondu
                            const correspondingQuestion = questionsParFacteurRepondu.find(q =>  
                                q.reponsesPositives.some(reponse => reponse.id === question.id) ||
                                q.reponsesNegatives.some(reponse => reponse.id === question.id)
                            );
                            // vérifie l'état de la question
                            const isAnswered = correspondingQuestion !== undefined;

                            // Si la réponse est positive ou non (si elle l'es la réponse coché sera vrai, sinon elle est fausse.)
                            const isPositive = isAnswered && correspondingQuestion.reponsesPositives.some(reponse => reponse.id === question.id);
                            if (isAnswered) {
                                // affiche le nombre de question répondu
                                totalQuestionnaireRepondu++;
                            }
                            // affiche le total de question par page
                            totalQuestionnairePage = questionsParFacteur.length;

                            return (
                                <Box key={index} className="box-texte-formulaire">
                                    <Typography className="question-texte-vf">{question.question}</Typography>
                                    <Box className="reponse">
                                        <RadioGroup row className="radioButton-vf" onChange={(event) => handleRadioChange(question.id, question.type, event.target.value)}>
                                            <FormControlLabel value="vrai" control={<Radio checked={isPositive && isAnswered} />} label="" className="radio-vrai"/>
                                            <FormControlLabel value="faux" control={<Radio checked={!isPositive && isAnswered} />} label="" className="radio-faux" />
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    </Box>
                    <Box className="box-bouton-formulaire">
                        {questionnaire === 1 ? <Button variant="contained" className="button-action-locked" onClick={handlePrevClick}> Précédent </Button> : ""}
                        {questionnaire > 1 ? <Button variant="contained" className="button-action" onClick={handlePrevClick}> Précédent </Button> : ''}
                        <Button variant="contained" className="button-action" onClick={handleNextClick}>
                        {questionnaire === listeFacteur.length ? 'Terminer' : 'Suivant'}
                        </Button>
                    </Box>
                    <Modale open={open} handleClose={handleClose} titre={'Confirmation'} phrase={phrase} route={route} />
                    <Box className="box-sous-titre">
                        <Typography className="sous-titre">{sousTitre}</Typography>
                        <Typography className="sous-titre">{totalQuestionnaireRepondu}/{totalQuestionnairePage}</Typography>
                    </Box>
                </form>
            </Box>
        </Box>
  );
}