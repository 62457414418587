import * as React from 'react';
import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem} from '@mui/material';
import { Link } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { useAppContext } from '../context/AppContext';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import '../css/header.css';
import DirectiveModale from '../modale/DirectiveModale';
import { useDirectiveContext } from '../context/DirectiveContext';
const logo = require('../assets/logo_cegep.png');
const people = require('../assets/people.jpg')


function Navbar() {
  const { isLogged, setIsLogged, email } = useUserContext();
  const { setTitreDirective, setObjectifs } = useDirectiveContext();
  const { setBearerToken } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };


  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    setIsLogged(false);
    setBearerToken("");
    setTitreDirective('Directive :');
    setObjectifs(["Ce menu contextuel affichera les consignes des différentes pages."]);
  }


  return (
    <AppBar elevation={0} position="static" className="header" style={{ backgroundColor: '#ffffff' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} alt="Logo" className="logo" />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="aCompte" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} className="nav-icon">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              className="menu-items"
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/bienvenue" className="texte-nav-button">ACCUEIL</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography variant="body1" component="div">
                  <Typography variant="body1" component="span" className="texte-nav-button">DIRECTIVE</Typography>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/vrai-faux" className="texte-nav-button">VRAI-FAUX</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/resultat" className="texte-nav-button">RESULTAT V-F</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/question-longue" className="texte-nav-button">QUESTION LONGUE</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/moyen-concret" className="texte-nav-button">MOYEN CONCRET</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/moyen-concret-resultat" className="texte-nav-button">MOYEN CONCRET - RÉSULTAT</Link>
              </MenuItem>
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          {isLogged && (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link to="/bienvenue" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Accueil</Typography></Button></Link>
              <Link to="#" className="texte-nav-button" onClick={() => setOpen(true)}><Button className="nav-button"><Typography>Directive</Typography></Button></Link>
              <Link to="/vrai-faux" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Vrai-Faux</Typography></Button></Link>
              <Link to="/resultat" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Resultat</Typography></Button></Link>
              <Link to="/question-longue" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Question Longue</Typography></Button></Link>
              <Link to="/moyen-concret" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Moyen Concret</Typography></Button></Link>
              <Link to="/moyen-concret-resultat" className="texte-nav-button" onClick={handleCloseNavMenu}><Button className="nav-button"><Typography>Moyen Concret - Résultat</Typography></Button></Link>
            </Box>
          )}
          <Box>
            {isLogged && (
                <Tooltip title={email}>
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar alt="Test" src={people} />
                  </IconButton>
                </Tooltip>
            )}
            <Menu sx={{ mt: '45px' }} anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
              <Link onClick={handleLogout} to="/connexion" className="texte-nav-button">
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography className="texte-nav-button">Se déconnecter</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
        </Toolbar>
        <DirectiveModale open={open} handleClose={handleClose} />
      </Container>
    </AppBar>
  );
}

export default Navbar;