// Source : MUI : https://mui.com/material-ui/all-components/
// Gestion Liste : https://www.robinwieruch.de/react-remove-item-from-list/
import { useEffect, useState } from 'react';
import { List, Typography, Box, ListItem, Button, Grid } from '@mui/material';
import Linkify from 'linkify-react';
import Modale from '../modale/modale';
import { useAppContext } from '../context/AppContext';
import { useDirectiveContext } from '../context/DirectiveContext';

import '../css/global.css';
import '../css/moyenConcret.css';



interface Actions {
    id: string;
    type: string;
    action: string;
}

interface Ressources {
    id: string;
    type: string;
    ressource: string;
}

export default function MoyenConcretResultat() {
    const { settingApiAddress, bearerToken } = useAppContext();
    const { setTitreDirective, setObjectifs } = useDirectiveContext();
    const [userActions, setUserActions] = useState<Actions[]>([]);
    const [userRessources, setUserRessources] = useState<Ressources[]>([]);
    const [sousTitre, setSousTitre] = useState("");
    const [piedPage, setPiedPage] = useState("");
    const [loading, setLoading] = useState(true);



    const handleClose = () => {
        setOpen(false);
      };



    const [open, setOpen] = useState(false);
    const [phrase, setPhrase] = useState('');
    const [route, setRoute] = useState('');
    const handleNextForms = () => {
        // Définir la phrase et la route pour la modale
        setPhrase("Votre questionnaire est maintenant terminé ! Merci de votre participation. Votre API va vous contacter sous peu pour le suivi.");
        setRoute("/");
        setOpen(true);
      };

    async function getReponseRessourceActionFromUser() {
        const questionsData = await fetch(settingApiAddress + "questionnaire-moyens-pour-reussir/getReponseRessourceActionFromUser", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
        });
        try {
            const { actions, ressources } = await questionsData.json();
            setUserActions(actions);
            setUserRessources(ressources);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getReponseRessourceActionFromUser();
        setLoading(false);
        setTitreDirective("Moyens pour réussir - Résultat");
        setObjectifs([("Voici les résultats de vos choix pour les moyens pour réussir.")]);
        setSousTitre("Voici les actions et ressources que vous avez sélectionnées pour les moyens pour réussir.");
        setPiedPage("Appuyer sur terminer pour compléter le questionnaire.");
    }, []);
    
    if (loading) {
        return <div>Loading...</div>;
    }


    
    return (
        <Box className="main">
            <Box className="page-box">
                <form className="form-style">
                    <Box className="box-titre-sous-titre">
                        <Box className="box-titre-formulaire">
                            <Typography className="titre-texte"></Typography>
                            <Typography className="titre-texte">Résultat de vos choix</Typography>
                            <Typography className="titre-texte"></Typography>
                        </Box>
                        <Typography className="sous-titre">{sousTitre}</Typography>
                    </Box>
                        <Box className="question-list-box">
                            <Box className="action-box">
                                <Box className="box-titre-concret">
                                    <Box className="box-bordure">
                                        <Typography className='titre-texte-concret'>Action Personnelles</Typography>
                                    </Box>
                                    <Box className="box-bordure">
                                        <Typography className='titre-texte-concret'>Ressource du Collège</Typography>
                                    </Box>
                                </Box>
                                <Box className="box-titre-concret">
                                    <Box className="box-bordure-max">
                                    {userActions.map((action, index) => (
                                        <List key={index}>
                                            <ListItem className="list-action-ressource">
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                    <Typography className="texte-action-ressource">
                                                        <Linkify>
                                                            {action.action}
                                                        </Linkify>
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    ))}
                                    </Box>
                                    <Box className="box-bordure-max">
                                    {userRessources.map((ressource, index) => (
                                        <List key={index}>
                                            <ListItem className="list-action-ressource">
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                    <Typography className="texte-action-ressource">
                                                        <Linkify>
                                                            {ressource.ressource}
                                                        </Linkify>
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    <Box className="box-bouton-formulaire">
                        <Button variant="contained" className="button-action" onClick={() => handleNextForms()}> Terminer </Button>
                    </Box>
                    <Modale open={open} handleClose={handleClose} titre={'Confirmation'} phrase={phrase} route={route} />
                    <Box>
                        <Typography className="sous-titre">{piedPage}</Typography>
                    </Box>
    
                </form>
            </Box>
        </Box>
    );
}