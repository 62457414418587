// footer.tsx
import { Grid, Typography, Link } from '@mui/material';
import '../css/footer.css';

const logo = require('../assets/logo_cegep.png');

const Footer = () => (
    <footer className="footer">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <img className="logo" src={logo} alt="Logo" />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body2" className="copyright">
            {"Droit d'auteur © "}
            <Link color="inherit" href="https://www.cegeprdl.ca/">
              Cégep de Rivière-du-Loup
            </Link>{', '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Grid>
      </Grid>
    </footer>
);
export default Footer;