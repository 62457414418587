// Source : MUI : https://mui.com/material-ui/all-components/
// Gestion Liste : https://www.robinwieruch.de/react-remove-item-from-list/
import { useEffect, useState } from 'react';
import { List, Typography, Box, ListItem, Button, Checkbox, Grid } from '@mui/material';
import Linkify from 'linkify-react';
import Modale from '../modale/modale';
import { useAppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useDirectiveContext } from '../context/DirectiveContext';

import '../css/global.css';
import '../css/moyenConcret.css';


interface Directive {
    titre: string;
    objectifs: string[];
}

interface QuestionMoyenReussite {
    facteur: string;
    titre: string;
    actions: Actions[];
    ressources: Ressources[];
}

interface Actions {
    id: string;
    type: string;
    action: string;
}

interface Ressources {
    id: string;
    type: string;
    ressource: string;
}

interface MoyensPourReussir {
    titre: string;
    piedPage: string;
    sousTitre: string;
    directives: Directive[];
    questionMoyenReussite: QuestionMoyenReussite[];
}

export default function MoyenConcret() {
    const [openResultModal, setOpenResultModal] = useState(false);
    const [userActions, setUserActions] = useState<Actions[]>([]);
    const [userRessources, setUserRessources] = useState<Ressources[]>([]);
    const [loading, setLoading] = useState(true);
    const [typeIndex, setTypeIndex] = useState(0); 
    const [listeFacteur, setListeFacteur] = useState<string[]>([]);
    const [listeTitre, setListeTitre] = useState<string[]>([]);
    const [sousTitre, setSousTitre] = useState('');
    const [grandTitre, setGrandTitre] = useState('');
    const [piedPage, setPiedPage] = useState('');
    const [questions, setQuestions] = useState<QuestionMoyenReussite[]>([]);
    const { settingApiAddress, bearerToken } = useAppContext();
    const { setTitreDirective, setObjectifs } = useDirectiveContext();
    const navigate = useNavigate();
    let facteur = listeFacteur[typeIndex]; 
    let titre = listeTitre[typeIndex];
    let questionnaire : number = typeIndex + 1;
    let totalQuestionnaire : number = listeTitre.length;

    const handlePrevClick = () => { 
        if (typeIndex > 0) {
            setTypeIndex(typeIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (typeIndex < listeTitre.length - 1) {
            setTypeIndex(typeIndex + 1) ;
        }
        else {
            handleResultModal();
            // handleNextForms();
        }
    };

    const showAllAnswers = () => {
        setOpenResultModal(false);
        navigate('/moyen-concret-resultat');
    };


    const handleClose = () => {
        setOpen(false);
      };

    const handleResultModal = () => {
        setOpenResultModal(!openResultModal);
    };


    const [open, setOpen] = useState(false);
    const [phrase, setPhrase] = useState('');
    const [route, setRoute] = useState('');
    const handleNextForms = () => {
        setOpenResultModal(false);
        // Définir la phrase et la route pour la modale
        setPhrase("Votre questionnaire est maintenant terminé ! Merci de votre participation. Votre API va vous contacter sous peu pour le suivi.");
        setRoute("/");
        setOpen(true);
      };

    async function getAllQuestions() {
        const questionsData = await fetch(settingApiAddress + "questionnaire-moyens-pour-reussir/getAllQuestions", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
        });
        try {
            const data = await questionsData.json() as MoyensPourReussir[];
            setGrandTitre(data[0].titre);
            setPiedPage(data[0].piedPage);
            setSousTitre(data[0].sousTitre);
            setTitreDirective(data[0].directives[0].titre);
            setObjectifs(data[0].directives[0].objectifs);
            setQuestions(data[0].questionMoyenReussite);
            setListeFacteur(data[0].questionMoyenReussite.map((question) => question.facteur));
            setListeTitre(data[0].questionMoyenReussite.map((question) => question.titre));
        } catch (error) {
            console.error(error);
        }
    }

    async function addRessourceToUser(id: string, type: string, ressource: string) {
        let ressources: Ressources = {id, type, ressource};
        const ressourceData = await fetch(settingApiAddress + "questionnaire-moyens-pour-reussir/addRessourceToUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
            body: JSON.stringify({
                ressource: [ressources],
            }),
        });
        if (ressourceData.ok) {
            // console.log('Ressource modifié avec succès');
            getReponseRessourceActionFromUser();
        } else {
            try {
                if (ressourceData.status !== 200) {
                    // console.log('Ressource déjà existante');
                }
                // console.log(data);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function addActionToUser(id: string, type: string, action: string) {
        let actions: Actions = {id, type, action};
        const actionData = await fetch(settingApiAddress + "questionnaire-moyens-pour-reussir/addActionToUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
            body: JSON.stringify({
                action: [actions],
            }),
        });
        if (actionData.ok) {
            // console.log('Action modifié avec succès');
            getReponseRessourceActionFromUser();
        } else {
            try {
                if (actionData.status !== 200) {
                    // console.log('Action déjà existante');
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function getReponseRessourceActionFromUser() {
        const questionsData = await fetch(settingApiAddress + "questionnaire-moyens-pour-reussir/getReponseRessourceActionFromUser", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
        });
        try {
            const { actions, ressources } = await questionsData.json();
            setUserActions(actions);
            setUserRessources(ressources);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getAllQuestions();
    }
    , []);

    useEffect(() => {
        async function fetchData() {
            await getAllQuestions();
        }
        fetchData();
    }, []);

    useEffect(() => {
        getReponseRessourceActionFromUser();
        setLoading(false);
    }, []);
    
    if (loading) {
        return <div>Loading...</div>;
    }


    
    return (
        <Box className="main">
            <Box className="page-box">
            <Typography className="titre">{grandTitre}</Typography>
                <form className="form-style">
                    <Box className="box-titre-sous-titre">
                        <Box className="box-titre-formulaire">
                            <Typography className="titre-texte">{questionnaire}/{totalQuestionnaire}</Typography>
                            <Typography className="titre-texte">{facteur}, {titre}</Typography>
                            <Typography className="titre-texte"></Typography>
                        </Box>
                        <Typography className="sous-titre">{sousTitre}</Typography>
                    </Box>
                        <Box className="question-list-box">
                            <Box className="action-box">
                                <Box className="box-titre-concret">
                                    <Box className="box-bordure">
                                        <Typography className='titre-texte-concret'>Action Personnelles</Typography>
                                    </Box>
                                    <Box className="box-bordure">
                                        <Typography className='titre-texte-concret'>Ressource du Collège</Typography>
                                    </Box>
                                </Box>
                                <Box className="box-titre-concret">
                                    <Box className="box-bordure-max">
                                    {typeIndex !== undefined && questions[typeIndex] && questions[typeIndex].actions.map((action, index) => (
                                        <List key={index}>
                                            <ListItem className="list-action-ressource">
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                    <Typography className="texte-action-ressource">
                                                        <Linkify>
                                                            {action.action}
                                                        </Linkify>
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Checkbox 
                                                            onClick={() => addActionToUser(action.id, action.type, action.action)} 
                                                            className='checkbox-action-ressource' 
                                                            checked={userActions.some(userAction => userAction.id === action.id)} // Pré-sélectionnez si l'action existe déjà
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    ))}
                                    </Box>
                                    <Box className="box-bordure-max">
                                    {typeIndex !== undefined && questions[typeIndex] && questions[typeIndex].ressources.map((ressource, index) => (
                                        <List key={index}>
                                            <ListItem className="list-action-ressource">
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                    <Typography className="texte-action-ressource">
                                                        <Linkify>
                                                            {ressource.ressource}
                                                        </Linkify>
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Checkbox 
                                                            onClick={() => addRessourceToUser(ressource.id, ressource.type, ressource.ressource)} 
                                                            className='checkbox-action-ressource' 
                                                            checked={userRessources.some(userRessource => userRessource.id === ressource.id)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    <Box className="box-bouton-formulaire">
                        {questionnaire === 1 ? <Button variant="contained" className="button-action-locked" onClick={handlePrevClick}> Précédent </Button> : ""}
                        {questionnaire > 1 ? <Button variant="contained" className="button-action" onClick={handlePrevClick}> Précédent </Button> : ''}
                        <Button variant="contained" className="button-action" onClick={handleNextClick}> Suivant </Button>
                    </Box>
                    <Modale open={open} handleClose={handleClose} titre={'Confirmation'} phrase={phrase} route={route} />
                    <Modale open={openResultModal} handleClose={handleResultModal} titre={'Voir les résultats ?'} phrase={"Voulez-vous voir l'ensemble des choix sélectionné(s) ?..."} route={'/moyen-concret'} onCancel={() => {handleNextForms()}} onConfirm={() => {showAllAnswers()}} />
                    <Box>
                        <Typography className="sous-titre">{piedPage}</Typography>
                    </Box>
    
                </form>
            </Box>
        </Box>
    );
}