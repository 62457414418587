// Source : MUI : https://mui.com/material-ui/all-components/
// Gestion Liste : https://www.robinwieruch.de/react-remove-item-from-list/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Slider, TextField, Button } from '@mui/material';
import Modale from '../modale/modale';
import { useAppContext} from '../context/AppContext';
import { useDirectiveContext } from '../context/DirectiveContext';
import { useUserContext } from '../context/UserContext';

import '../css/global.css';
import '../css/questionLongue.css';


interface Question {
    id: string;
    enonce: string;
}

interface QuestionProgrammeEtudes {
    type: string;
    id: string;
    titre: string;
    sousTitre: string;
    questions: Question[];
}

interface MonProgrammeEtudes {
    titre: string;
    directives: { titre: string, objectifs: string[] }[];
    piedPage: string;
    questionProgrammeEtudes: QuestionProgrammeEtudes[];
}

interface ReponseScore {
    type: string;
    score: number;
}

interface ReponseQuestion {
    id: string;
    type: string;
    reponse: string;
}

export default function QuestionLongue() {
    const [typeIndex, setTypeIndex] = useState(0); 
    const [listeType, setListeType] = useState<string[]>([]);
    const [type, setType] = useState('');
    const [grandTitre, setGrandTitre] = useState('');
    const [piedPage, setPiedPage] = useState('');
    const [reponseFacteur, setReponseFacteur] = useState<ReponseScore[]>([]);
    const [reponseQuestion, setReponseQuestion] = useState<ReponseQuestion[]>([]);
    const [questions, setQuestions] = useState<QuestionProgrammeEtudes[]>([]);
    const [listePriorite, setListePriorite] = useState<string[]>([]);
    const { settingApiAddress, bearerToken } = useAppContext();
    const { setTitreDirective, setObjectifs } = useDirectiveContext();
    const { setIsLogged } = useUserContext();
    const [priorite, setPriorite] = useState('');
    const navigate = useNavigate();


    const handlePrevClick = () => { 
         addQuestionToUser(reponseQuestion);
        if (typeIndex > 0) {
            setTypeIndex(typeIndex - 1);
            setType(listeType[typeIndex - 1]);
        }
    };

    const handleNextClick = () => {
        addQuestionToUser(reponseQuestion);
        if (typeIndex < listeType.length - 1) {
            setTypeIndex(typeIndex + 1);
            setType(listeType[typeIndex + 1]);
        }
        else {
            handleNextForms();
        }
    };

    async function getAllQuestionsByAnswer() {
        const questionsData = await fetch(settingApiAddress + "questionnaire-mon-programme-etudes/getAllQuestionsByAnswer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
            body: JSON.stringify({ reponseFacteur: reponseFacteur }),
        });
        try {
            const data = await questionsData.json() as MonProgrammeEtudes[];
            if (data.length > 0 && data[0].questionProgrammeEtudes.length > 0) {
                setGrandTitre(data[0].titre);
                setPiedPage(data[0].piedPage);
                setTitreDirective(data[0].directives[0].titre);
                setObjectifs(data[0].directives[0].objectifs);
                setQuestions(data[0].questionProgrammeEtudes);
                setListeType(data[0].questionProgrammeEtudes.map((question) => question.type));
                setQuestions(data[typeIndex].questionProgrammeEtudes);
                setType(data[0].questionProgrammeEtudes[0].type);
            } else {
                setPhrase("Vous avez répondu faux a tout les questions. Vous semblez ne pas avoir besoin de ce questionnaire... Modifiez vos réponses ?");
                setRoute("/vrai-faux");
                setOpen(true);
                setTitreModale("Erreur");
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAllReponses() {
        const reponseData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/getAllScoreReponses", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + bearerToken,
            },
          });
          try {
            const data = await reponseData.json() as ReponseScore[];
            setReponseFacteur(data);
            if (data.length < 9 ) {
              navigate('/resultat');
            }
          } catch (error) {
            console.error(error);
          }
    }

    async function getReponseByType() {
        const reponseData = await fetch(settingApiAddress + "questionnaire-mon-programme-etudes/getReponseByType/" + type, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
        });
        try {
            const data = await reponseData.json() as ReponseQuestion[];
            // console.log("GET REPONSE BY TYPE");
            // console.log(data);
    
            data.forEach((newReponse) => {
                // Vérifie si la nouvelle réponse existe déjà dans questionReponse
                const exists = reponseQuestion.find((existingReponse) => existingReponse.id === newReponse.id);
    
                // Si la réponse n'existe pas déjà, ajoutez-la à questionReponse
                if (!exists) {
                    setReponseQuestion((prev) => [...prev, newReponse]);
                    if (newReponse.type === "E") {
                        newReponse.reponse.split(', ').forEach((priorite) => {
                            setListePriorite((prev) => [...prev, priorite]); // Ajoutez priorite à listePriorite
                        });
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function addQuestionToUser(reponseQuestion : ReponseQuestion[]) {
        const questions = reponseQuestion.map((reponse) => ({
            id: reponse.id,
            type: reponse.type,
            reponse: reponse.reponse,
        }));
    
        const reponseData = await fetch(settingApiAddress + "questionnaire-mon-programme-etudes/addQuestionToUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + bearerToken,
            },
            body: JSON.stringify({ reponse: questions }), 
        });
        // console.log(questions);
    
        try {
            // console.log(data);
            if (reponseData.status === 200) {
                // console.log(data.message); // "Question(s) ajouté avec succès"
            } else {
                // console.error(data.message); // Affiche l'erreur
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleAddClick = () => {
        if (priorite === '') {
            setPhrase("Veuillez entrer un element valide...");
            setRoute("/question-longue");
            setOpen(true);
            setTitreModale("Erreur");
        } else {
            // Ajouter la priorité à la liste des priorités
            setListePriorite(prevListePriorite => [...prevListePriorite, priorite]);
    
            // Pour chaque question de type "E", ajouter la priorité à reponseQuestion
            const questionsTypeE = questions[typeIndex].type === "E" ? questions[typeIndex].questions : [];
            const newReponseQuestion = [...reponseQuestion];
    
            for (let question of questionsTypeE) {
                const responseIndex = newReponseQuestion.findIndex((r) => r.id === question.id);
    
                if (responseIndex !== -1) {
                    // Si une réponse existe déjà, la mettre à jour
                    newReponseQuestion[responseIndex] = {
                        ...newReponseQuestion[responseIndex],
                        reponse: newReponseQuestion[responseIndex].reponse ? `${newReponseQuestion[responseIndex].reponse}, ${priorite}` : priorite
                    };
                } else {
                    // Sinon, ajouter une nouvelle réponse
                    newReponseQuestion.push({
                        id: question.id,
                        reponse: priorite,
                        type: "E"
                    });
                }
            }
    
            // Mettre à jour reponseQuestion (et l'ajoute avant)
            setReponseQuestion(newReponseQuestion);
    
            // Réinitialiser la priorité
            setPriorite('');
        }
    };

    const handleRemoveClick = (index: number) => {
        const prioriteToRemove = listePriorite[index];
        const newList = listePriorite.filter((_, i) => i !== index);
        setListePriorite(newList);
    
        // Créer une copie de reponseQuestion
        const newReponseQuestion = reponseQuestion.map((r) => {
            if (r.type === "E") {
                // Si le type est "E", supprimer la priorité de la réponse
                const newReponse = r.reponse.split(', ').filter(p => p !== prioriteToRemove).join(', ');
                return {
                    ...r,
                    reponse: newReponse
                };
            } else {
                // Sinon, retourner la réponse telle quelle
                return r;
            }
        });
        // Mettre à jour reponseQuestion
        setReponseQuestion(newReponseQuestion);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const returnToLogin = () => {
        setIsLogged(false);
        navigate('/');
    }


    const [open, setOpen] = useState(false);
    const [phrase, setPhrase] = useState('');
    const [route, setRoute] = useState('');
    const [titreModale, setTitreModale] = useState('');
    const handleNextForms = () => {
        // Définir la phrase et la route pour la modale
        setPhrase("Section terminée, cliquez sur suivant pour passer a la prochaine section...");
        setRoute("/moyen-concret");
        setOpen(true);
        setTitreModale("Terminé");
      };

    let questionnaire : number = typeIndex + 1;
    let totalQuestionnaire : number = listeType.length;

    useEffect(() => {
        async function fetchData() {
            await getAllReponses();
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        if (reponseFacteur.length > 0) {
            getAllQuestionsByAnswer();
        }
    }, [reponseFacteur]);

    useEffect(() => {
        if (type.length > 0) {
            // console.log("GET REPONSE BY TYPE");
            getReponseByType();
        }
    }, [type]);

    useEffect(() => {
        // Cette fonction sera appelée chaque fois que la listePriorite est mis à jour
        addQuestionToUser(reponseQuestion);
    }, [listePriorite]); 
    
    return (
        <Box className="main">
            <Box className="page-box">
            <Typography className="titre">{grandTitre}</Typography>
                <form className="form-style">
                    {questions && questions[typeIndex] && (
                        <>
                            <Box className="box-titre-sous-titre">
                                <Box className="box-titre-formulaire">
                                    <Typography className="titre-texte">{questionnaire}/{totalQuestionnaire}</Typography>
                                    <Typography className="titre-texte">{questions[typeIndex].titre}</Typography>
                                    <Typography className="titre-texte"></Typography>
                                </Box>
                                <Typography className="sous-titre">{questions[typeIndex].sousTitre}</Typography>
                            </Box>
                            {(type === "T" || type ==="M" || type ==="C" || type === "A" || type ==="X") && (
                                <Box className="question-list-box">
                                    {(() => {
                                        // Obtenir la question actuelle
                                        const question = questions[typeIndex].questions[0];

                                        // Trouver la réponse existante
                                        const existingResponse = reponseQuestion.find((r) => r.id === question.id);

                                        return (
                                            <>
                                                <Box className="box-texte-formulaire">
                                                    <Typography className="question-texte">{question.enonce}</Typography>
                                                </Box>
                                                <Box className="reponse">
                                                    <TextField 
                                                        id="outlined-multiline-static" 
                                                        multiline 
                                                        rows={4} 
                                                        placeholder="Entrez votre réponse longue ici..." 
                                                        variant="outlined" 
                                                        value={existingResponse ? existingResponse.reponse : ''} 
                                                        className="reponse-zone-texte"
                                                        onChange={(event) => {
                                                            // Trouver l'index de la réponse existante
                                                            const index = reponseQuestion.findIndex((r) => r.id === question.id);
                                                        
                                                            // Créer une copie de reponseQuestion
                                                            const newReponseQuestion = [...reponseQuestion];
                                                        
                                                            if (index !== -1) {
                                                                // Si une réponse existe déjà, la mettre à jour
                                                                newReponseQuestion[index] = {
                                                                    id: question.id,
                                                                    reponse: event.target.value,
                                                                    type: questions[typeIndex].type
                                                                };
                                                            } else {
                                                                // Sinon, ajouter une nouvelle réponse
                                                                newReponseQuestion.push({
                                                                    id: question.id,
                                                                    reponse: event.target.value,
                                                                    type: questions[typeIndex].type
                                                                });
                                                            }
                                                        
                                                            // Mettre à jour reponseQuestion
                                                            setReponseQuestion(newReponseQuestion);
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        );
                                    })()}
                                </Box>
                            )}
                           {(type === "S" || type === "I") && (
                            <Box className="question-list-box">
                                    <Box className="question-list-box">
                                        {questions[typeIndex].questions.map((question, questionIndex) => {
                                            // Trouver la réponse existante

                                            // Trouver la réponse existante
                                            const existingResponse = reponseQuestion.find((r) => r.id === question.id);

                                            return (
                                                <Box key={questionIndex}>
                                                    <Box className="box-texte-formulaire">
                                                        <Typography className="question-texte">{question.enonce}</Typography>
                                                    </Box>
                                                    <Box className="reponse">
                                                        <TextField 
                                                            multiline 
                                                            placeholder="Entrez votre réponse courte ici..." 
                                                            variant="outlined" 
                                                            className="reponse-zone-texte-petit"
                                                            value={existingResponse ? existingResponse.reponse : ''} // Utiliser la réponse existante ou une chaîne vide si aucune réponse n'existe
                                                            onChange={(event) => {
                                                                // Trouver l'index de la réponse existante
                                                                const responseIndex = reponseQuestion.findIndex((r) => r.id === question.id);
                                                            
                                                                // Créer une copie de reponseQuestion
                                                                const newReponseQuestion = [...reponseQuestion];
                                                            
                                                                if (responseIndex !== -1) {
                                                                    // Si une réponse existe déjà, la mettre à jour
                                                                    newReponseQuestion[responseIndex] = {
                                                                        id: question.id,
                                                                        reponse: event.target.value,
                                                                        type: questions[typeIndex].type
                                                                    };
                                                                } else {
                                                                    // Sinon, ajouter une nouvelle réponse
                                                                    newReponseQuestion.push({
                                                                        id: question.id,
                                                                        reponse: event.target.value,
                                                                        type: questions[typeIndex].type
                                                                    });
                                                                }
                                                            
                                                                // Mettre à jour reponseQuestion
                                                                setReponseQuestion(newReponseQuestion);
                                                            }}
                                                        />
                                                        <input type="hidden" value={question.id} id="hiddenQuestionId" />
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                            </Box>
                            )}
                            {type === "O" && (
                                <Box className="question-list-box">
                                    {questions[typeIndex].questions.map((question, index) => {
                                        // Trouver la réponse existante
                                        const existingResponse = reponseQuestion.find((r) => r.id === question.id);
                                        return (
                                            <Box key={index}>
                                                <Box className="box-texte-formulaire">
                                                    <Typography className="question-texte">{question.enonce}</Typography>
                                                </Box>
                                                <Box className="reponse">
                                                    <Slider 
                                                        className="slider-choix" 
                                                        aria-label="Temperature" 
                                                        defaultValue={5} 
                                                        valueLabelDisplay="auto" 
                                                        step={1} 
                                                        marks={[
                                                            { value: 0, label: '0 - Pas' },
                                                            { value: 5, label: '5 - Moyennement' },
                                                            { value: 10, label: '10 - Efficacement' },
                                                        ]} 
                                                        min={0} 
                                                        max={10}
                                                        value={existingResponse ? Number(existingResponse.reponse) : 5} 
                                                        onChange={(event, newValue) => {
                                                            // Trouver l'index de la réponse existante
                                                            const responseIndex = reponseQuestion.findIndex((r) => r.id === question.id);
                                                        
                                                            // Créer une copie de reponseQuestion
                                                            const newReponseQuestion = [...reponseQuestion];
                                                        
                                                            if (responseIndex !== -1) {
                                                                // Si une réponse existe déjà, la mettre à jour
                                                                newReponseQuestion[responseIndex] = {
                                                                    id: question.id,
                                                                    reponse: newValue.toString(),
                                                                    type: questions[typeIndex].type
                                                                };
                                                            } else {
                                                                // Sinon, ajouter une nouvelle réponse
                                                                newReponseQuestion.push({
                                                                    id: question.id,
                                                                    reponse: newValue.toString(),
                                                                    type: questions[typeIndex].type
                                                                });
                                                            }
                                                        
                                                            // Mettre à jour reponseQuestion
                                                            setReponseQuestion(newReponseQuestion);
                                                        }}
                                                    />
                                                    <input type="hidden" value={question.id} id="hiddenQuestionId" />
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                            {type === "E" && (
                                <Box className="question-list-box">
                                    {questions[typeIndex].questions.map((question, index) => {
                                        return (
                                            <Box key={index}>
                                                <Box className="box-texte-formulaire">
                                                    <Typography className="question-texte">{question.enonce}</Typography>
                                                </Box>
                                                <Box className="reponse-e">
                                                    <TextField 
                                                        value={priorite} 
                                                        onChange={(event) => {
                                                            setPriorite(event.target.value);
                                                        }}
                                                    />
                                                    <Button variant="contained" onClick={handleAddClick}>Ajouter</Button>
                                                </Box>
                                                <Box className="box-reponse-e">
                                                    {listePriorite.map((priorite, index) => (
                                                        <Box className="box-contenu-reponse-e" key={index}>
                                                            <Typography className="texte-contenu-reponse-e">{priorite}</Typography>
                                                            <Button variant="contained" onClick={() => handleRemoveClick(index)}>Supprimer</Button>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                            {questions === undefined && (
                                <Typography className="question-texte">Aucune question trouvée</Typography>
                            )}
                        </>
                    )}
                    <Box className="box-bouton-formulaire">
                        {questionnaire === 1 ? <Button variant="contained" className="button-action-locked" onClick={handlePrevClick}> Précédent </Button> : ""}
                        {questionnaire > 1 ? <Button variant="contained" className="button-action" onClick={handlePrevClick}> Précédent </Button> : ''}
                        <Button variant="contained" className="button-action" onClick={handleNextClick}>
                        {typeIndex === listeType.length - 1 ? 'Terminer' : 'Suivant'}
                        </Button>
                    </Box>
                    <Modale open={open} handleClose={handleClose} titre={titreModale} phrase={phrase} route={route} onCancel={returnToLogin} />
                    <Box>
                        <Typography className="sous-titre">{piedPage}</Typography>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}