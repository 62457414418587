import React, { createContext, ReactNode, useContext, useState } from "react";

interface DirectiveContextType {
  titreDirective: string;
  setTitreDirective: React.Dispatch<React.SetStateAction<string>>;
  objectifs: string[];
  setObjectifs: React.Dispatch<React.SetStateAction<string[]>>;
}

const DirectiveContext = createContext<DirectiveContextType | undefined>(undefined);

export const useDirectiveContext = () => {
  const context = useContext(DirectiveContext);

  if (!context) {
    throw new Error("useDirectiveContext must be used within a DirectiveProvider");
  }

  return context;
};

interface DirectiveProviderProps {
  children: ReactNode;
}

export const DirectiveProvider: React.FC<DirectiveProviderProps> = ({ children }) => {
  const [titreDirective, setTitreDirective] = useState<string>('Directive :');
  const [objectifs, setObjectifs] = useState<string[]>(['Ce menu contextuel affichera les consignes des différentes pages.']);

  const contextValue = {
    titreDirective,
    setTitreDirective,
    objectifs,
    setObjectifs,
  };

  return (
    <DirectiveContext.Provider value={contextValue}>{children}</DirectiveContext.Provider>
  );
};