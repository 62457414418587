import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './css/global.css';
import './css/inscription.css';

export default function Inscription() {
    const question = require('./assets/question.jpg')
    const personnage = require('./assets/personnage.jpg')

  return (
        <Box className="main">
            <Box className="form-box">
                <Typography className="titre-texte"> Plateforme de formulaire d'indécision vocationelle </Typography>
                    <Box className="box-img">
                        <img src={question} alt="Logo" style={{ width: '20vw', height: "15vw" }} />
                        <Box component="form" noValidate>
                        <Typography variant="h5" align="center"> Inscription</Typography>
                            <TextField margin="normal" required fullWidth id="email" label="Adresse de courriel" name="email" autoComplete="email" autoFocus/>
                            <Button type="submit" className="button" fullWidth variant="contained">Créer le compte </Button>
                        </Box>
                    <img src={personnage} alt="Logo" style={{ width: '20vw', height: "15vw"}} />
                    </Box>
            </Box>
        </Box>
  );
}