import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Modale from '../modale/modale';
import '../css/global.css';
import '../css/resultat.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';

let titre : string = "Résultat du questionnaire mon choix de programme :";
let sousTitre : string = "Voici les résultats de votre questionnaires";
const resultats = [
    { facteur: 'I', description: 'Identité professionnelle : Concordance des facteurs personnels avec le choix de carrière' },
    { facteur: 'S', description: 'Soutien : des amis, des proches et des professeurs' },
    { facteur: 'M', description: 'Métier : connaissance de la profession et du marché du travail' },
    { facteur: 'O', description: 'Cours/Cheminement : Efficacité personnelle dans le métier d\'étudiants'},
    { facteur: 'C', description: 'Choix de carriere : Clarté et maintien du choix de carrière' },
    { facteur: 'T', description: 'Cégep : Transition secondaire-collègial' },
    { facteur: 'A', description: 'Moi : Perception de soi et des attitudes' },
    { facteur: 'E', description: 'Engagement et projets de vie : Degré d\'engagement dans les études et autres projets de vie' },
    { facteur: 'X', description: 'Vie personnelle : État de ma situation personnelle' },
  ];




interface ReponseScore {
  type: string;
  score: string;
};



export default function Resultat() {

  const [open, setOpen] = useState(false);
  const [phrase, setPhrase] = useState('');
  const [route, setRoute] = useState('');
  const [reponseFacteur, setReponseFacteur] = useState<ReponseScore[]>([]);
  const { settingApiAddress, bearerToken } = useAppContext();

  const handleClose = () => {
    setOpen(false);
    navigate("/vrai-faux");
  };

  async function getAllReponses() {
    const questionsData = await fetch(settingApiAddress + "questionnaire-mon-choix-programme/getAllScoreReponses", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + bearerToken,
        },
      });
      try {
        const data = await questionsData.json() as ReponseScore[];
        // console.log(data);
        setReponseFacteur(data);
        // console.log("reponseFacteur");
        // console.log(reponseFacteur);
        if (data.length < 9 ) {
          setPhrase("Vous n'avez pas répondu à toutes les questions de la section vrai ou faux. Veuillez répondre à toutes les questions avant de procéder à cette section.");
          setRoute("/vrai-faux");
          setOpen(true);
        }
  
      } catch (error) {
        console.error(error);
      }
  }

  const navigate = useNavigate();
  const handleNextClick = () => {
    navigate('/question-longue');
  }

  useEffect(() => {
    getAllReponses();
  }
  , []);

  function determineColor(facteur: string, score: number) {
    if ((['I', 'S', 'M', 'O', 'C', 'T'].includes(facteur) && score >= 2) || 
        (['A', 'E', 'X'].includes(facteur) && score >= 1)) {
      return 'yellow';
    } else if (score === 0) {
      return 'green';
    } else {
      return 'black';
    }
  }

    return (
        <Box className="main">
          <Box className="page-box">
            <Typography className="titre">{titre}</Typography>
            <form className="form-style">
              <Box className="box-formulaire">
              <TableContainer component={Paper}>
                  <Table className="tableau-resultat">
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableau-section texte-centre" >Facteur</TableCell>
                        <TableCell className="tableau-section texte-centre" >Résultat</TableCell>
                        <TableCell className="tableau-section texte-centre" >Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {resultats.map((row) => {
                      // Recherche du score de la réponse pour le facteur
                      const score = reponseFacteur.find((item) => item.type === row.facteur)?.score;

                      return (
                        <TableRow key={row.facteur}>
                          <TableCell className="tableau-section texte-centre" >{row.facteur}</TableCell>
                          <TableCell className="tableau-section texte-centre" >
                            {(row.facteur === 'X' ? [0, 1, 2, 3, 4, 5, 6] : [0, 1, 2, 3, 4, 5]).map((num) => (
                              <span key={num} className="tableau-numero" style={{ color: num === Number(score) ? determineColor(row.facteur, num) : 'black' }}>{num} </span>
                            ))}
                          </TableCell>
                          <TableCell className="tableau-section" >{row.description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className="box-bouton-formulaire-resultat">
                  <Button variant="contained" className="button-action" onClick={handleNextClick}>Suivant</Button>
              </Box>
              <Modale open={open} handleClose={handleClose} titre={'Confirmation'} phrase={phrase} route={route} />
              <Box>
                <Typography className="sous-titre">{sousTitre}</Typography>
              </Box>
            </form>
          </Box>
        </Box>
      );
}