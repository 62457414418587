import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './css/global.css';
import './css/bienvenue.css';
import { useDirectiveContext } from './context/DirectiveContext';
import { useEffect } from 'react';




export default function Bienvenue() {
    const { setTitreDirective, setObjectifs } = useDirectiveContext();
    useEffect(() => {
        setTitreDirective('Directive :');
        setObjectifs(["Ce menu contextuel affichera les consignes des différentes pages."]);
      }, []);
    
    const bienvenue = "Bienvenue sur notre formulaire d'indécision vocationnelle !  Ce questionnaire a été conçu pour vous aider à mieux vous connaître, "+
    "autant sur niveau personnel que collégial, afin de vous orienter vers des choix qui correspondent le mieux à vos intérêts, compétences et choix...." +
    "Prenez votre temps pour répondre avec honnêteté et réflexion, car chaque réponse nous permettra de mieux vous aider sur votre cheminement. Bon succès !"
    return (
          <Box className="main">
              <Box className="box-message">
                  <Typography className="titre-texte"> Plateforme de formulaire d'indécision vocationelle </Typography>
                        <Box className="section">
                                <Typography className="texte-bienvenue" >
                                    {bienvenue}
                                </Typography>
                                <Link to="/vrai-faux"><Button variant="contained" className="button-bienvenue"> Continuer </Button></Link>
                        </Box>
                </Box>
          </Box>
    );
}