import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/modale.css';

interface ModaleProps {
  open: boolean;
  handleClose: () => void;
  titre: string;
  phrase: string;
  route: string;
  onCancel?: () => void;
  onConfirm?: () => void; // Ajoutez cette ligne
}

const Modale: FC<ModaleProps> = (props) => {
  const { open, handleClose, titre, phrase, route, onCancel, onConfirm } = props;
  const navigate = useNavigate();
  const handleConfirm = () => {
    handleClose();
    navigate(route);
  };

  const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      border: '5px solid lightgrey',
      borderRadius: 5,
    },
  });
  

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle className='titre-modale'>{titre}</DialogTitle>
      <DialogContent>
        <DialogContentText className="phrase-modale">
          {phrase}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="box-button-modale">
      <Button onClick={onCancel || handleClose}>
        Annuler
      </Button>
      <Button onClick={props.onConfirm || handleConfirm} color="primary" autoFocus>
        Ok
      </Button>
      </DialogActions>
    </StyledDialog>
  );
}   

export default Modale;