import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/modale.css';
import { useDirectiveContext } from '../context/DirectiveContext';
import React from 'react';

interface ModaleProps {
  open: boolean;
  handleClose: () => void;
}

const DirectiveModale: FC<ModaleProps> = ({ open, handleClose }) => {
  const { titreDirective, objectifs } = useDirectiveContext();
  const navigate = useNavigate();
  const handleConfirm = () => {
    handleClose();
  };

  const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      border: '5px solid lightgrey',
      borderRadius: 5,
    },
  });
  

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle className='titre-modale'>{titreDirective}</DialogTitle>
      <DialogContent>
      <DialogContentText className="phrase-modale">
          {objectifs.map((objectif, index) => (
              <React.Fragment key={index}>
                  <Typography component="span">
                      {objectif}
                  </Typography>
                  <br /><br />
              </React.Fragment>
          ))}
      </DialogContentText>
      </DialogContent>
      <DialogActions className="box-button-modale">
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default DirectiveModale;