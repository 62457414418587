import React from 'react';
import { Box } from '@mui/material';
import ErrorPage from '../404';
import Connexion from '../connexion';
import Inscription from '../inscription';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from '../design/header';
import Footer from '../design/footer';
import Bienvenue from '../bienvenue';
import VraiFaux from '../formulaire/VraiFaux';
import QuestionLongue from '../formulaire/questionLongue';
import Resultat from '../formulaire/resultat';
import MoyenConcret from '../formulaire/moyenConcret';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppProvider } from '../context/AppContext';
import { useUserContext, UserProvider } from '../context/UserContext';
import { useDirectiveContext, DirectiveProvider } from '../context/DirectiveContext';
import MoyenConcretResultat from '../formulaire/moyenConcretResultat';

const defaultTheme = createTheme();

const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <AppProvider>
      <UserProvider>
        <DirectiveProvider>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar />
            <Box sx={{ flex: '1 0 auto' }}>
              <Routes>
                <Route path="/" element={<Connexion />} />
                <Route path="/connexion" element={<Connexion />} />
                <Route path="/inscription" element={<Inscription />} />
                <Route path="/bienvenue" element={<PrivateRoute component={<Bienvenue />} />} />
                <Route path="/vrai-faux" element={<PrivateRoute component={<VraiFaux />} />} />
                <Route path="/question-longue" element={<PrivateRoute component={<QuestionLongue />} />} />
                <Route path="/resultat" element={<PrivateRoute component={<Resultat />} />} />
                <Route path="/moyen-concret" element={<PrivateRoute component={<MoyenConcret />} />} />
                <Route path="/moyen-concret-resultat" element={<PrivateRoute component={<MoyenConcretResultat />} />} />
                <Route path="/404" element={<ErrorPage />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
      </DirectiveProvider>
      </UserProvider>
    </AppProvider>
  </ThemeProvider>
);

const PrivateRoute = ({ component }: { component: React.ReactElement }) => {
  const { isLogged } = useUserContext();

  if (!isLogged) {
    return <Navigate to="/connexion" replace />;
  }

  return component;
};

export default App;