// Source, inspiré de mon code en Projet Technologique

import React, { createContext, ReactNode, useContext } from "react";

interface UserContextType {
  email : string,
  setEmail : React.Dispatch<React.SetStateAction<string>>;
  password : string
  setPassword : React.Dispatch<React.SetStateAction<string>>;  
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(''); 
  const [password, setPassword] = React.useState<string>('');

  const contextValue = {
    email,
    setEmail,
    password,
    setPassword,
    isLogged,
    setIsLogged,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
