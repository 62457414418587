import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './css/global.css';


export default function bienvenue() {

    const erreur = "La page que vous cherchez n'existe pas. Veuillez retourner a l'accueil."
    return (
          <Box className="main">
              <Box className="box-message">
                  <Typography className="titre-texte"> Erreur 404 </Typography>
                        <Box className="section">
                                <Typography className="texte-bienvenue" >
                                    {erreur}
                                </Typography>
                                <Link to="/"><Button variant="contained" className="button-bienvenue"> Accueil </Button></Link>
                        </Box>
                </Box>
          </Box>
    );
}